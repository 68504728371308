<script>
    import OverlayLink from "@/components/OverlayLink.svelte";

    export let privacyPolicy;
</script>

<p class="privacy-policy">
    <span>Les vår</span>
    <OverlayLink header="Personvern" label="personvernerklæring">
        {@html privacyPolicy}
    </OverlayLink>.
</p>

<style >
    p {
        display: flex;
        flex-direction: row;
    }

    span {
        padding-right: .2em;
    }
</style>