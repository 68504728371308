<script>
    import localizer from "~/common/i18n.js";
    import OverlayLink from "@/components/OverlayLink.svelte";
    import Checkbox from "@/components/Checkbox.svelte";

    export let checked = false;
    export let terms;

    const t = localizer({
        nb: {
            accept: 'Jeg aksepterer',
            terms: 'brukervilkårene'
        },
        nn: {
            accept: 'Eg godtek',
            terms: 'brukervilkåra'
        }
    });
</script>

<Checkbox name="accept_terms" value="accepted" {checked}>
    <div class="label">
        <span>{t('accept')}</span>
        <OverlayLink header="Brukervilkår" label={t('terms')}>
            <div id="aid-user-terms">
                {@html terms}
            </div>
        </OverlayLink>
    </div>
</Checkbox>

<style>
    .label {
        display: flex;
        flex-direction: row;
        gap: 0.15em;
    }
</style>